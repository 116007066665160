import React from 'react';
import './StyleCommon.css';
import { Container, Row, Col } from "react-bootstrap";
import sachin from '../Assets/photographer/sachin/sachin.png';
import image01 from '../Assets/photographer/sachin/01.png';
import image02 from '../Assets/photographer/sachin/02.png';
import image03 from '../Assets/photographer/sachin/03.png';
import image04 from '../Assets/photographer/sachin/04.png';
import image05 from '../Assets/photographer/sachin/05.png';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';


function SachinRai() {
  
    return (
        <div>
            <section className="photoExhibitionDiv">
                <Container>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={3} xl={3}>
                            <div className="alignLeft">
                                <img data-aos="fade-down" data-aos-duration="3000" src={sachin} className="profileImage" alt="SachinBiswas"></img>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={9} xl={9}>
                            <div data-aos="fade-up" data-aos-duration="3000">
                                <h4 className="namePhotographer">Sachin Biswas</h4>
                                <p className="aboutPhotographer">As a wildlife photographer, Sachin Rai has travelled to many countries in search of rare, elusive & curious animals. He has been photographing wildlife and their threatened habitat for about two decades before which he was involved with a digital media design venture. Sachin’s photographs have been featured in national & international media and has received several awards as well.  
                                <br></br> <br></br>
                                Apart from mammals, frogs are his favourite and he also helped re-discover a new species of frog -Ramanella anamalaeinsis during one of his expeditions. He wishes that his art conveys hope for wildlife and humanity. “I would like my art to inspire curiosity about the natural world and help people reconnect with nature”, he says.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div className="divider">
                        <Row>
                            <Col className="p-0" sm={1} xs={1} md={5} lg={5} xl={5}>
                                <hr className="line"></hr>
                            </Col>
                            <Col className="p-0" sm={10} xs={10} md={5} lg={5} xl={5}>
                                <p className="dividerTxt">Photograph’s By Sachin Biswas</p>
                            </Col>
                            <Col className="p-0" sm={1} xs={1} md={2} lg={2} xl={2}>
                                <hr className="line"></hr>
                            </Col>
                        </Row>
                    </div>
                    {/* photo gallery starts */}
                    <div className="mainGallery">
                    <Gallery shareButton={false}>
                        <div className="firstImage">
                        <Item
                                original={image01}
                                thumbnail={image01}
                                width="1920"
                                height="1280"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image01} alt="sachin01"/>
                                )}
                            </Item>
                        </div>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image02}
                                thumbnail={image02}
                                width="1920"
                                height="1281"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image02} alt="sachin02"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image03}
                                thumbnail={image03}
                                width="1080"
                                height="1621"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image03} alt="sachin03"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image04}
                                thumbnail={image04}
                                width="1080"
                                height="1618"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image04} alt="sachin04"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image05}
                                thumbnail={image05}
                                width="1080"
                                height="1621"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image05} alt="sachin05"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        </Gallery>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default SachinRai