import React  from 'react';
import './StyleCommon.css';
import { Container, Row, Col } from "react-bootstrap";
import dhritiman from '../Assets/photographer/dhritiman/dhritiman.png';
import image01 from '../Assets/photographer/dhritiman/01.png';
import image02 from '../Assets/photographer/dhritiman/02.png';
import image03 from '../Assets/photographer/dhritiman/03.png';
import image04 from '../Assets/photographer/dhritiman/04.png';
import image05 from '../Assets/photographer/dhritiman/05.png';
import image06 from '../Assets/photographer/dhritiman/06.png';
import image07 from '../Assets/photographer/dhritiman/07.png';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';


function DhritimanMukharjee() {
  
    return (
        <div>
            <section className="photoExhibitionDiv">
                <Container>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={3} xl={3}>
                            <div className="alignLeft">
                                <img data-aos="fade-down" data-aos-duration="3000" src={dhritiman} className="profileImage" alt="dhritiman mukharjee"></img>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={9} xl={9}>
                            <div data-aos="fade-up" data-aos-duration="3000"> 
                                <h4 className="namePhotographer">Dhritiman Mukharjee</h4>
                                <p className="aboutPhotographer">One of India’s most accomplished nature and wildlife photographers, Dhritiman Mukherjee is an explorer and wanderer. In the last twenty years, he has pushed his limits as a photographer to do work that is compelling as well as scientifically essential.
                                <br></br> <br></br>
                                His work has been published in some of the most respected websites and publications like BBC Wildlife, National Geographic Traveller, Lonely Planet, Biographic, New York Times and many more. Dhritiman has co-authored the book Magical Biodiversity of India and was one of the co-founders of nature and wildlife magazine - Saevus. He was nominated as one of the jury members of the 6th National Award for photography by the Government of India.
                                <br></br> <br></br>
                                He spends almost 300 days every year out in the jungles and diving in the oceans all over the world and there is no other life he’d ever choose.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="divider">
                        <Row>
                            <Col className="p-0" sm={1} xs={1} md={5} lg={5} xl={5}>
                                <hr className="line"></hr>
                            </Col>
                            <Col className="p-0" sm={10} xs={10} md={5} lg={5} xl={5}>
                                <p className="dividerTxt">Photograph’s By Dhritiman Mukharjee</p>
                            </Col>
                            <Col className="p-0" sm={1} xs={1} md={2} lg={2} xl={2}>
                                <hr className="line"></hr>
                            </Col>
                        </Row>
                    </div>
                    {/* photo gallery starts */}
                    <div className="mainGallery">
                    <Gallery shareButton={false}>
                        <div className="firstImage">
                        <Item
                                original={image01}
                                thumbnail={image01}
                                width="1920"
                                height="1162"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image01} alt="dhritiman01"/>
                                )}
                            </Item>
                        </div>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image02}
                                thumbnail={image02}
                                width="1920"
                                height="1335"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image02} alt="dhritiman02"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image03}
                                thumbnail={image03}
                                width="1920"
                                height="1224"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image03} alt="dhritiman03"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image04}
                                thumbnail={image04}
                                width="1920"
                                height="1236"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image04} alt="dhritiman04"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image05}
                                thumbnail={image05}
                                width="1920"
                                height="1279"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image05} alt="dhritiman05"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image06}
                                thumbnail={image06}
                                width="1920"
                                height="1320"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image06} alt="dhritiman06"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image07}
                                thumbnail={image07}
                                width="1920"
                                height="1224"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image07} alt="dhritiman07"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        </Gallery>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default DhritimanMukharjee