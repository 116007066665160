import React from 'react';
import './StyleCommon.css';
import { Container, Row, Col } from "react-bootstrap";
import rajesh from '../Assets/photographer/rajesh/rajesh.png';
import image01 from '../Assets/photographer/rajesh/01.png';
import image02 from '../Assets/photographer/rajesh/02.png';
import image03 from '../Assets/photographer/rajesh/03.png';
import image04 from '../Assets/photographer/rajesh/04.png';
import image05 from '../Assets/photographer/rajesh/05.png';
import image06 from '../Assets/photographer/rajesh/06.png';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';


function RajeshBedi() {
  
    return (
        <div>
            <section className="photoExhibitionDiv">
                <Container>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={3} xl={3}>
                            <div className="alignLeft">
                                <img data-aos="fade-down" data-aos-duration="3000" src={rajesh} className="profileImage" alt="RajeshBedi"></img>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={9} xl={9}>
                            <div data-aos="fade-up" data-aos-duration="3000">
                                <h4 className="namePhotographer">RajeshBedi</h4>
                                <p className="aboutPhotographer">Rajesh Bedi began his photographic career in the 1960s, this was a time when wildlife photography was not known and the condition of wildlife park was in a poor state. This was the time when Rajesh Bedi won awards. 
                                <br></br> <br></br>
                                His images have introduced the magnificent wildlife to millions. His encounters with nature resulted in two visually stunning books, ‘Indian Wildlife’ and the much acclaimed ‘India’s Wild Wonders’.
                                <br></br> <br></br>
                                He was the first Asian to win Wildlife Photographer of the year in 1986 in an international competition held in Britain. In 2014 he was awarded ‘The Lifetime Photography Award’ by the Ministry of Information & Broadcasting, Government of India. Rajesh has had many of his remarkable images published in prestigious magazines such as National Geographic USA, Life USA, Geo, Bunte and Stern. He is winner of several National and International awards for nature photography.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div className="divider">
                        <Row>
                            <Col className="p-0" sm={1} xs={1} md={5} lg={5} xl={5}>
                                <hr className="line"></hr>
                            </Col>
                            <Col className="p-0" sm={10} xs={10} md={5} lg={5} xl={5}>
                                <p className="dividerTxt">Photograph’s By Rajesh Bedi</p>
                            </Col>
                            <Col className="p-0" sm={1} xs={1} md={2} lg={2} xl={2}>
                                <hr className="line"></hr>
                            </Col>
                        </Row>
                    </div>
                    {/* photo gallery starts */}
                    <div className="mainGallery">
                    <Gallery shareButton={false}>
                        <div className="firstImage">
                        <Item
                                original={image01}
                                thumbnail={image01}
                                width="1920"
                                height="966"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image01} alt="rajesh01"/>
                                )}
                            </Item>
                        </div>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image02}
                                thumbnail={image02}
                                width="1920"
                                height="847"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image02} alt="rajesh02"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image03}
                                thumbnail={image03}
                                width="1920"
                                height="848"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image03} alt="rajesh03"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image04}
                                thumbnail={image04}
                                width="1920"
                                height="1389"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image04} alt="rajesh04"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image05}
                                thumbnail={image05}
                                width="1920"
                                height="1373"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image05} alt="rajesh05"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image06}
                                thumbnail={image06}
                                width="1920"
                                height="1363"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image06} alt="rajesh06"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            </Col>
                        </Row>
                        </Gallery>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default RajeshBedi