import React from 'react';
import './StyleCommon.css';
import { Container, Row, Col } from "react-bootstrap";
import giri from '../Assets/photographer/giri/giri.png';
import image01 from '../Assets/photographer/giri/01.png';
import image02 from '../Assets/photographer/giri/02.png';
import image03 from '../Assets/photographer/giri/03.png';
import image04 from '../Assets/photographer/giri/04.png';
import image05 from '../Assets/photographer/giri/05.png';
import image06 from '../Assets/photographer/giri/06.png';
import image07 from '../Assets/photographer/giri/07.png';
import image08 from '../Assets/photographer/giri/08.png';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';


function GiriCavale() {
  
    return (
        <div>
            <section className="photoExhibitionDiv">
                <Container>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={3} xl={3}>
                            <div className="alignLeft">
                                <img data-aos="fade-down" data-aos-duration="3000" src={giri} className="profileImage" alt="giri cavale"></img>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={9} xl={9}>
                            <div data-aos="fade-up" data-aos-duration="3000">
                                <h4 className="namePhotographer">Giri Cavale</h4>
                                <p className="aboutPhotographer">Giri Cavale is a renowned wildlife photographer and a photography mentor. His mercurial senses are always in search of an exceptional experience and his seasoned expertise keeps him always ready for a memorable image.
                                <br></br> <br></br>
                                Giri’s passion for photography was born out of his love for the wilds, which was instilled in him from a young age. Armed with this experience, almost unfaltering intuition, a deep connection with his subjects and tremendous skill to make opportunities count, Giri has captured some truly unforgettable moments in the most enchanting wildlife areas.Two of his films are on air on Animal Planet and Discovery, Gyamo - Queen of the Mountains and Looking for Sultan. Both films have been awarded internationally and recognised for their conservation message.
                                <br></br> <br></br>
                                He is equally passionate about sharing his treasure of knowledge, as he is about using photography as a powerful tool of communication.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div className="divider">
                        <Row>
                            <Col className="p-0" sm={1} xs={1} md={5} lg={5} xl={5}>
                                <hr className="line"></hr>
                            </Col>
                            <Col className="p-0" sm={10} xs={10} md={5} lg={5} xl={5}>
                                <p className="dividerTxt">Photograph’s By Giri Cavale</p>
                            </Col>
                            <Col className="p-0" sm={1} xs={1} md={2} lg={2} xl={2}>
                                <hr className="line"></hr>
                            </Col>
                        </Row>
                    </div>
                    {/* photo gallery starts */}
                    <div className="mainGallery">
                    <Gallery shareButton={false}>
                        <div className="firstImage">
                        <Item
                                original={image01}
                                thumbnail={image01}
                                width="1920"
                                height="1278"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image01} alt="giri01"/>
                                )}
                            </Item>
                        </div>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image02}
                                thumbnail={image02}
                                width="1920"
                                height="1280"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image02} alt="giri02"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image04}
                                thumbnail={image04}
                                width="1920"
                                height="1272"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image04} alt="giri04"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image08}
                                thumbnail={image08}
                                width="1920"
                                height="1281"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image08} alt="giri08"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image05}
                                thumbnail={image05}
                                width="1920"
                                height="1278"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image05} alt="giri05"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image06}
                                thumbnail={image06}
                                width="1920"
                                height="1278"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image06} alt="giri06"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image07}
                                thumbnail={image07}
                                width="1920"
                                height="1278"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image07} alt="giri07"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image03}
                                thumbnail={image03}
                                width="1080"
                                height="1620"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image03} alt="giri03"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        </Gallery>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default GiriCavale