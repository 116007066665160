import React from 'react';
import './StyleCommon.css';
import { Container, Row, Col } from "react-bootstrap";
import kalyan from '../Assets/photographer/kalyan/kalyan.png';
import image01 from '../Assets/photographer/kalyan/01.png';
import image02 from '../Assets/photographer/kalyan/02.png';
import image03 from '../Assets/photographer/kalyan/03.png';
import image04 from '../Assets/photographer/kalyan/04.png';
import image05 from '../Assets/photographer/kalyan/05.png';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';


function KalyanVarma() {
  
    return (
        <div>
            <section className="photoExhibitionDiv">
                <Container>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={3} xl={3}>
                            <div className="alignLeft">
                                <img data-aos="fade-down" data-aos-duration="3000" src={kalyan} className="profileImage" alt="Kalyan Varma"></img>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={9} xl={9}>
                            <div data-aos="fade-up" data-aos-duration="3000">
                                <h4 className="namePhotographer">Kalyan Varma</h4>
                                <p className="aboutPhotographer">Kalyan Varma is a wildlife photographer, filmmaker, naturalist and explorer who is dedicated to documenting wildlife and the environmental issues that define current times. He is a freelancer and has worked with many of the world's leading magazines, environmental NGOs and television channels like Nat Geo and BBC. 
                                <br></br> <br></br>
                                Over the last decade, Kalyan has worked on many landmark blue chip wildlife series for the BBC and National Geographic channel. His work has appeared in many publications worldwide, including National Geographic, Nature, The Guardian, BBC Wildlife, GEO, Smithsonian, Lonely Planet and other magazines.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div className="divider">
                        <Row>
                            <Col className="p-0" sm={1} xs={1} md={5} lg={5} xl={5}>
                                <hr className="line"></hr>
                            </Col>
                            <Col className="p-0" sm={10} xs={10} md={5} lg={5} xl={5}>
                                <p className="dividerTxt">Photograph’s By Kalyan Varma</p>
                            </Col>
                            <Col className="p-0" sm={1} xs={1} md={2} lg={2} xl={2}>
                                <hr className="line"></hr>
                            </Col>
                        </Row>
                    </div>
                    {/* photo gallery starts */}
                    <div className="mainGallery">
                    <Gallery shareButton={false}>
                        <div className="firstImage">
                        <Item
                                original={image01}
                                thumbnail={image01}
                                width="1920"
                                height="1080"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image01} alt="kalyan01"/>
                                )}
                            </Item>
                        </div>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image02}
                                thumbnail={image02}
                                width="1920"
                                height="1278"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image02} alt="kalyan02"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image03}
                                thumbnail={image03}
                                width="1920"
                                height="1279"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image03} alt="kalyan03"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image04}
                                thumbnail={image04}
                                width="1920"
                                height="1920"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image04} alt="kalyan04"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image05}
                                thumbnail={image05}
                                width="1920"
                                height="1278"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image05} alt="kalyan05"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        </Gallery>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default KalyanVarma