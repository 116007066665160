import React  from 'react'
import './Footer.css';
import { Container, Row, Col } from "react-bootstrap";
import facebook from "../Assets/social/facebook.svg";
import instagram from "../Assets/social/instagram.svg";
import footerLogo from '../Assets/footerLogo.svg';

function Footer() {
  
    return (
        <div>
            <section className="footerDiv">
                <Container>
                    <Row>
                        <Col sm={12} xs={12} md={4} lg={4} xl={4}>
                            <div>
                                <img data-aos="slide-down" data-aos-duration="2000" className="footerLogoStyle" src={footerLogo} alt="kviff logo"></img>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={1} lg={1} xl={1}></Col>
                        <Col sm={12} xs={12} md={2} lg={2} xl={2}>
                            <div className="centerStyle">
                                <h3 className="footerHead">Quick Links</h3>
                                <div className="alignLinks">
                                    <p className="footerLinks">
                                        <a href="/" className="footerLinks">Home</a>
                                    </p>
                                    <p className="footerLinks">
                                        <a href="/Theme" className="footerLinks">Theme</a>
                                    </p>
                                    <p className="footerLinks">
                                        <a href="/Exhibition" className="footerLinks">Exhibition</a>
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={1} lg={1} xl={1}></Col>
                        <Col sm={12} xs={12} md={4} lg={4} xl={4}>
                            <div>
                                <h3 className="footerHead">TALK TO US</h3>
                                <div className="alignLinks">
                                    <p className="footerLinks">
                                        <a href="mailto:photokviff@gmail.com" className="footerLinks">photokviff@gmail.com</a>
                                    </p>
                                    <p className="footerLinks">
                                        <a href="https://wa.me/+919850078917" rel="noopener noreferrer" target="_blank" className="footerLinks">+91 98500 78917</a>
                                    </p>
                                </div>
                                <div className="socialDiv">
                                    <a href="https://www.facebook.com/kirloskarvasundharafilmfest" rel="noopener noreferrer" target="_blank">
                                        <img src={facebook} className="socialIcon" alt="facebook"></img>
                                    </a>
                                    <a href="https://www.instagram.com/kirloskarvasundhara/" rel="noopener noreferrer" target="_blank">
                                        <img src={instagram} className="socialIcon" alt="instagram"></img>
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="copyrightDiv">
                    <p className="copyrightTxt">© 2021 Kirloskar Vasundhara International Film Festival (KVIFF). All Rights Reserved. Designed & Developed by <a href="https://www.makebelieve.in/" target="_blank" className="styleTag">Makebelieve
                        </a> </p>
                </div>
            </section>
        </div>
    )
}

export default Footer