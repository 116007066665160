import React, { useState, useEffect } from "react"
import './Header.css';
import logo from "../Assets/logo.svg";
import { Container, Navbar, Nav } from "react-bootstrap";


const Header = () => {
  //navbar scroll when active state
  const [navbar, setNavbar] = useState(false)

  //navbar scroll changeBackground function
  const changeBackground = () => {
    console.log(window.scrollY)
    if (window.scrollY >= 44) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })

  return (
    <Navbar fixed="top"  expand="lg" className={navbar ? "navbar active" : "navbar"}>
    <Container>
      <Navbar.Brand href="/">
          <img className="styleMainLogo" alt="main logo kviff" src={logo}></img>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse className="styleAuto" id="navbarScroll">
        <Nav className="me-auto my-2 my-lg-0">
          <Nav.Link className="navLinkStyle" href="/">Home</Nav.Link>
          <Nav.Link className="navLinkStyle" href="/Theme">Theme</Nav.Link>
          <Nav.Link className="navLinkStyle" href="/Exhibition">Exhibition</Nav.Link>
          <div className="dView">
            <a href="mailto:photokviff@gmail.com" target="_blank" rel="noopener noreferrer" className="btnContact">Contact Us</a>
          </div>
        </Nav>
      </Navbar.Collapse>
        <div className="mView">
            <a href="mailto:photokviff@gmail.com" target="_blank" rel="noopener noreferrer" className="btnContact pageActive">Contact Us</a>
        </div>
    </Container>
  </Navbar>
  )
}

export default Header