import React from 'react';
import './StyleCommon.css';
import { Container, Row, Col } from "react-bootstrap";
import gautam from '../Assets/photographer/gautam/gautam.png';
import image01 from '../Assets/photographer/gautam/01.png';
import image02 from '../Assets/photographer/gautam/02.png';
import image03 from '../Assets/photographer/gautam/03.png';
import image04 from '../Assets/photographer/gautam/04.png';
import image05 from '../Assets/photographer/gautam/05.png';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';


function GautamPandey() {
  
    return (
        <div>
            <section className="photoExhibitionDiv">
                <Container>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={3} xl={3}>
                            <div className="alignLeft">
                                <img data-aos="fade-down" data-aos-duration="3000" src={gautam} className="profileImage" alt="Gautam Pandey"></img>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={9} xl={9}>
                            <div data-aos="fade-up" data-aos-duration="3000">
                                <h4 className="namePhotographer">Gautam Pandey</h4>
                                <p className="aboutPhotographer">Gautam Pandey is one of India’s accomplished wildlife filmmakers and studied film in Canada and has won several national and international awards for his films including the National award. Beyond awards Gautam lives with a passion for the wild. Born into a family of nature-enthusiasts his passion for filmmaking started at an early age and he is now focused on using film as a medium to bring about awareness about wildlife and combine the genres of Natural History and Conservation films.
                                <br></br> <br></br>
                                Two of his films are on air on Animal Planet and Discovery, Gyamo - Queen of the Mountains and Looking for Sultan. Both films have been awarded internationally and recognised for their conservation message.
                                <br></br> <br></br>
                                Gautam has been experimenting with technology and for the past couple of years has been experimenting with cinematic 360 VR to create immersive experiences to tell stories and deliver conservation messages to new audiences. Most recently he was successful in filming wild snow leopards in 360 VR, a first in the world of wildlife filmmaking.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div className="divider">
                        <Row>
                            <Col className="p-0" sm={1} xs={1} md={5} lg={5} xl={5}>
                                <hr className="line"></hr>
                            </Col>
                            <Col className="p-0" sm={10} xs={10} md={5} lg={5} xl={5}>
                                <p className="dividerTxt">Photograph’s By Gautam Pandey</p>
                            </Col>
                            <Col className="p-0" sm={1} xs={1} md={2} lg={2} xl={2}>
                                <hr className="line"></hr>
                            </Col>
                        </Row>
                    </div>
                    {/* photo gallery starts */}
                    <div className="mainGallery">
                    <Gallery shareButton={false}>
                        <div className="firstImage">
                        <Item
                                original={image01}
                                thumbnail={image01}
                                width="1920"
                                height="1080"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image01} alt="gautam01"/>
                                )}
                            </Item>
                        </div>
                        <Row className="spaceDown">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image02}
                                thumbnail={image02}
                                width="1080"
                                height="1508"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image02} alt="gautam02"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image04}
                                thumbnail={image04}
                                width="1080"
                                height="1350"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image04} alt="gautam04"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image03}
                                thumbnail={image03}
                                width="1920"
                                height="1080"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image03} alt="gautam03"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image05}
                                thumbnail={image05}
                                width="1920"
                                height="1594"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image05} alt="gautam05"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        </Gallery>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default GautamPandey