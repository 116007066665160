import React from 'react'
import './Exhibition.css';
import { Container, Row, Col } from "react-bootstrap";
import dharmendra from '../Assets/photographer/dharmendrakhandal/dharmendra.png';
import dhritiman from '../Assets/photographer/dhritiman/dhritiman.png';
import dinesh from '../Assets/photographer/dinesh/dinesh.png';
import ganesh from '../Assets/photographer/ganesh/ganesh.png';
import ganeshs from '../Assets/photographer/ganeshs/ganeshs.png';
import gautam from '../Assets/photographer/gautam/gautam.png';
import giri from '../Assets/photographer/giri/giri.png';
import jainy from '../Assets/photographer/jainy/jainy.png';
import kalyan from '../Assets/photographer/kalyan/kalyan.png';
import rajesh from '../Assets/photographer/rajesh/rajesh.png';
import ripan from '../Assets/photographer/ripan/ripan.png';
import sachin from '../Assets/photographer/sachin/sachin.png';
import shantanu from '../Assets/photographer/shantanu/shantanu.png';
import shivashankar from '../Assets/photographer/shivashankar/shivashankar.png'
import sudhir from '../Assets/photographer/sudhir/sudhir.png';
import suhas from '../Assets/photographer/suhas/suhas.png';
import sangram from '../Assets/photographer/sangram/sangram.png';

function Exhibition() {
  
    return (
        <div>
            <section className="exhibitionDivFirst">
                <Container>
                    <h1 data-aos="fade-up" data-aos-duration="3000" className="mainHeadExhibition">OUR PHOTOGRAPHERS</h1>
                </Container>
            </section>
            <section className="photographerDiv">
                <Container>
                    <Row className="spaceBottom">
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                            <div className="photographerCard">
                                <a href="/DharmendraKhandal">
                                    <div className="imagePhotographer">
                                        <img src={dharmendra} className="photographerCardImage" alt="kviff - Dharmendra Khandal"></img>
                                    </div>
                                    <div className="bottomCard">
                                        <p className="photographerName">Dharmendra Khandal</p>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <div className="photographerCard">
                            <a href="/DhritimanMukharjee">
                                <div className="imagePhotographer">
                                    <img src={dhritiman} className="photographerCardImage" alt="kviff - Dhritiman Mukharjee"></img>
                                </div>
                                <div className="bottomCard">
                                    <p className="photographerName">Dhritiman Mukharjee</p>
                                </div>
                            </a>
                        </div>
                        </Col>
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <div className="photographerCard">
                            <a href="/DineshKumble">
                                <div className="imagePhotographer">
                                    <img src={dinesh} className="photographerCardImage" alt="kviff - Dinesh Kumble"></img>
                                </div>
                                <div className="bottomCard">
                                    <p className="photographerName">Dinesh Kumble</p>
                                </div>
                            </a>
                        </div>
                        </Col>
                    </Row>
                    <Row className="spaceBottom">
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                            <div className="photographerCard">
                                <a href="/GaneshBagal">
                                    <div className="imagePhotographer">
                                        <img src={ganesh} className="photographerCardImage" alt="kviff - Ganesh Bagal"></img>
                                    </div>
                                    <div className="bottomCard">
                                        <p className="photographerName">Ganesh Bagal</p>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <div className="photographerCard">
                            <a href="/GaneshShankar">
                                <div className="imagePhotographer">
                                    <img src={ganeshs} className="photographerCardImage" alt="kviff - Ganesh Shankar"></img>
                                </div>
                                <div className="bottomCard">
                                    <p className="photographerName">Ganesh Shankar</p>
                                </div>
                            </a>
                        </div>
                        </Col>
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <div className="photographerCard">
                            <a href="/GautamPandey">
                                <div className="imagePhotographer">
                                    <img src={gautam} className="photographerCardImage" alt="kviff - Gautam Pandey"></img>
                                </div>
                                <div className="bottomCard">
                                    <p className="photographerName">Gautam Pandey</p>
                                </div>
                            </a>
                        </div>
                        </Col>
                    </Row>
                    <Row className="spaceBottom">
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                            <div className="photographerCard">
                                <a href="/GiriCavale">
                                    <div className="imagePhotographer">
                                        <img src={giri} className="photographerCardImage" alt="kviff - Giri Cavale"></img>
                                    </div>
                                    <div className="bottomCard">
                                        <p className="photographerName">Giri Cavale</p>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <div className="photographerCard">
                                <a href="/SuhasNalawade">
                                    <div className="imagePhotographer">
                                        <img src={suhas} className="photographerCardImage" alt="kviff - Suhas Nalawade"></img>
                                    </div>
                                    <div className="bottomCard">
                                        <p className="photographerName">Suhas Nalawade</p>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <div className="photographerCard">
                            <a href="/JainyMaria">
                                <div className="imagePhotographer">
                                    <img src={jainy} className="photographerCardImage" alt="kviff - Jainy Maria"></img>
                                </div>
                                <div className="bottomCard">
                                    <p className="photographerName">Jainy Maria</p>
                                </div>
                            </a>
                        </div>
                        </Col>
                    </Row>
                    <Row className="spaceBottom">
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                            <div className="photographerCard">
                                <a href="/RajeshBedi">
                                    <div className="imagePhotographer">
                                        <img src={rajesh} className="photographerCardImage" alt="kviff - Rajesh Bedi"></img>
                                    </div>
                                    <div className="bottomCard">
                                        <p className="photographerName">Rajesh Bedi</p>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <div className="photographerCard">
                            <a href="/RipanBiswas">
                                <div className="imagePhotographer">
                                    <img src={ripan} className="photographerCardImage" alt="kviff - Ripan Biswas"></img>
                                </div>
                                <div className="bottomCard">
                                    <p className="photographerName">Ripan Biswas</p>
                                </div>
                            </a>
                        </div>
                        </Col>
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <div className="photographerCard">
                            <a href="/SachinRai">
                                <div className="imagePhotographer">
                                    <img src={sachin} className="photographerCardImage" alt="kviff - Sachin Rai"></img>
                                </div>
                                <div className="bottomCard">
                                    <p className="photographerName">Sachin Rai</p>
                                </div>
                            </a>
                        </div>
                        </Col>
                    </Row>
                    <Row className="spaceBottom">
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                            <div className="photographerCard">
                                <a href="/ShantanuPrasad">
                                    <div className="imagePhotographer">
                                        <img src={shantanu} className="photographerCardImage" alt="kviff - Shantanu Prasad"></img>
                                    </div>
                                    <div className="bottomCard">
                                        <p className="photographerName">Shantanu Prasad</p>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <div className="photographerCard">
                            <a href="/ShivashankarBanagar">
                                <div className="imagePhotographer">
                                    <img src={shivashankar} className="photographerCardImage" alt="kviff - Shivashankar Banagar"></img>
                                </div>
                                <div className="bottomCard">
                                    <p className="photographerName">Shivashankar Banagar</p>
                                </div>
                            </a>
                        </div>
                        </Col>
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <div className="photographerCard">
                            <a href="/SudhirShivaram">
                                <div className="imagePhotographer">
                                    <img src={sudhir} className="photographerCardImage" alt="kviff - Sudhir Shivaram"></img>
                                </div>
                                <div className="bottomCard">
                                    <p className="photographerName">Sudhir Shivaram</p>
                                </div>
                            </a>
                        </div>
                        </Col>
                    </Row>
                    <Row className="spaceBottom">
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                            <div className="photographerCard">
                                <a href="/KalyanVarma">
                                    <div className="imagePhotographer">
                                        <img src={kalyan} className="photographerCardImage" alt="kviff - Kalyan Varma"></img>
                                    </div>
                                    <div className="bottomCard">
                                        <p className="photographerName">Kalyan Varma</p>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        <div className="photographerCard">
                            <a href="/SangramGovardhane">
                                <div className="imagePhotographer">
                                    <img src={sangram} className="photographerCardImage" alt="kviff - Sangram Govardhane"></img>
                                </div>
                                <div className="bottomCard">
                                    <p className="photographerName">Sangram Govardhane</p>
                                </div>
                            </a>
                        </div>
                        </Col>
                        <Col data-aos="fade-up" data-aos-duration="3000" sm={12} xs={12} md={4} lg={4} xl={4}>
                        </Col>
                    </Row>
                </Container>
            </section>

        </div>
    )
}

export default Exhibition