import './App.css';
import React, { useEffect, useState } from "react";
import Header from './Components/Header.js';
import Home from './Components/Home.js';
import Theme from './Components/Theme.js';
import Exhibition from './Components/Exhibition.js';
import Footer from './Components/Footer.js';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

// photographers component import
import DharmendraKhandal from './Photographers/DharmendraKhandal.js';
import DhritimanMukharjee from './Photographers/DhritimanMukharjee.js';
import DineshKumble from './Photographers/DineshKumble.js';
import GaneshBagal from './Photographers/GaneshBagal.js';
import GaneshShankar from './Photographers/GaneshShankar.js';
import GautamPandey from './Photographers/GautamPandey.js';
import GiriCavale from './Photographers/GiriCavale.js';
import HemantOgale from './Photographers/HemantOgale.js';
import JainyMaria from './Photographers/JainyMaria.js';
import RajeshBedi from './Photographers/RajeshBedi.js';
import RipanBiswas from './Photographers/RipanBiswas.js';
import SachinRai from './Photographers/SachinRai.js';
import ShantanuPrasad from './Photographers/ShantanuPrasad.js';
import ShivashankarBanagar from './Photographers/ShivashankarBanagar.js';
import SudhirShivaram from './Photographers/SudhirShivaram.js';
import SuhasNalawade from './Photographers/SuhasNalawade.js';
import KalyanVarma from './Photographers/KalyanVarma.js';
import SangramGovardhane from './Photographers/SangramGovardhane.js';
import arrow from "./Assets/arrow.svg";
import bgSound from "./Assets/sound/bgSound.mp3";


  document.addEventListener('contextmenu', event => event.preventDefault());
  document.onkeypress = function (event) {  
    event = (event || window.event);  
    if (event.keyCode === 123) {  
    return false;  
    }  
  }  
    document.onmousedown = function (event) {  
    event = (event || window.event);  
    if (event.keyCode === 123) {  
    return false;  
    }  
  }  
    document.onkeydown = function (event) {  
    event = (event || window.event);  
    if (event.keyCode === 123) {  
    return false;  
    }  
  } 

function App() {

  const [allowedToVisit, setAllowedToVisit] = useState(!!window.sessionStorage.getItem('enter'));
  const [videoPlaying, setVideoPlaying] = useState(false);
  useEffect(() => {
    videoPlaying ? pauseAudio() : playAudio();
  })
    const handleClose = () => {
        window.sessionStorage.setItem('enter', true);
        setAllowedToVisit(true);
    }

    useEffect(()=>{
        if(window.sessionStorage.getItem('enter') === true){
            setAllowedToVisit(true);
        }
    },[])
  
  // Set the top cordinate to 0
  const [isVisible, setIsVisible] = useState(false);
  let vidPlayerRef = React.createRef;
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  
 
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  // audio added in background
  // window.addEventListener("DOMContentLoaded", event => {
  //   const audio = document.querySelector("audio");
  //   audio.volume = 0.3;
  //   audio.play();
  // });
  // audio ended
  const playAudio = () => {
    const audio = document.querySelector("audio");
    audio.volume = 0.3;
    audio.play();
  }
  const pauseAudio = () => {
    const audio = document.querySelector("audio");
    audio.volume = 0.0;
    audio.pause(); 
  }


  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);


  return (
    <div  className="scroll-to-top">
      {isVisible && 
        <div onClick={scrollToTop}>
          <img className="scroll-top" src={arrow} alt='Go to top'/>
        </div>}

        <audio loop src={bgSound}></audio>
    <Router>
      <div className="App">
        <Header/>
        <Routes>
          <Route path="/" element={<Home ref={vidPlayerRef} setVideoPlaying={setVideoPlaying} allowedToVisit={allowedToVisit} handleClose={handleClose}/>}>
          </Route>
          <Route exact path="/Theme" element={<Theme/>} >
          </Route>
          <Route exact path="/Exhibition" element={<Exhibition/>} >
          </Route>
          <Route exact path="/DharmendraKhandal" element={<DharmendraKhandal/>} >
          </Route>
          <Route exact path="/DhritimanMukharjee" element={<DhritimanMukharjee/>} >
          </Route>
          <Route exact path="/DineshKumble" element={<DineshKumble/>} >
          </Route>
          <Route exact path="/GaneshBagal" element={<GaneshBagal/>} >
          </Route>
          <Route exact path="/GaneshShankar" element={<GaneshShankar/>} >
          </Route>
          <Route exact path="/GautamPandey" element={<GautamPandey/>} >
          </Route>
          <Route exact path="/GiriCavale" element={<GiriCavale/>} >
          </Route>
          <Route exact path="/HemantOgale" element={<HemantOgale/>} >
          </Route>
          <Route exact path="/JainyMaria" element={<JainyMaria/>} >
          </Route>
          <Route exact path="/KalyanVarma" element={<KalyanVarma/>} >
          </Route>
          <Route exact path="/RajeshBedi" element={<RajeshBedi/>} >
          </Route>
          <Route exact path="/RipanBiswas" element={<RipanBiswas/>} >
          </Route>
          <Route exact path="/SachinRai" element={<SachinRai/>} >
          </Route>
          <Route exact path="/ShantanuPrasad" element={<ShantanuPrasad/>} >
          </Route>
          <Route exact path="/ShivashankarBanagar" element={<ShivashankarBanagar/>} >
          </Route>
          <Route exact path="/SudhirShivaram" element={<SudhirShivaram/>} >
          </Route>
          <Route exact path="/SuhasNalawade" element={<SuhasNalawade/>} >
          </Route>
          <Route exact path="/SangramGovardhane" element={<SangramGovardhane/>} >
          </Route>
        </Routes>
        <Footer/>
      </div>
    </Router>
    </div>
    
  );
}
AOS.init();
export default App;
