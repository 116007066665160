import React from 'react';
import './StyleCommon.css';
import { Container, Row, Col } from "react-bootstrap";
import ripan from '../Assets/photographer/ripan/ripan.png';
import image01 from '../Assets/photographer/ripan/01.png';
import image02 from '../Assets/photographer/ripan/02.png';
import image03 from '../Assets/photographer/ripan/03.png';
import image04 from '../Assets/photographer/ripan/04.png';
import image05 from '../Assets/photographer/ripan/05.png';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';


function RipanBiswas() {
  
    return (
        <div>
            <section className="photoExhibitionDiv">
                <Container>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={3} xl={3}>
                            <div className="alignLeft">
                                <img data-aos="fade-down" data-aos-duration="3000" src={ripan} className="profileImage" alt="RipanBiswas"></img>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={9} xl={9}>
                            <div data-aos="fade-up" data-aos-duration="3000">
                                <h4 className="namePhotographer">Ripan Biswas</h4>
                                <p className="aboutPhotographer">Ripan Biswas belong to a small village of Coochbehar, West Bengal, India. He has completed his masters in Environmental Science. Nature has always been his inspiration since childhood. “The life and death of a grasshopper, the flashing green hue of a bee eater, the sound of smashing dry leaves on a forest floor in spring always kept me enthralled” says Ripan.  
                                <br></br> <br></br>
                                He started nature photography in 2008 and macro photography of insects is a special attraction to him.
                                <br></br> <br></br>
                                His pictures are published in various national and international magazines like Sanctuary Asia & BBC Wildlife Magazine, and he has received many National and international awards in wildlife photography competition. He is the only Indian wildlife photographer who has won the "Wildlife photographer of the year" consecutively two times. This award is popularly known as the "Oscar of wildlife photography". In 2019, Ripan was the winner in "Portrait" category and in 2020 he won "Wildlife portfolio" category.Spreading awareness through his photography and work is his main aim! 
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div className="divider">
                        <Row>
                            <Col className="p-0" sm={1} xs={1} md={5} lg={5} xl={5}>
                                <hr className="line"></hr>
                            </Col>
                            <Col className="p-0" sm={10} xs={10} md={5} lg={5} xl={5}>
                                <p className="dividerTxt">Photograph’s By Ripan Biswas</p>
                            </Col>
                            <Col className="p-0" sm={1} xs={1} md={2} lg={2} xl={2}>
                                <hr className="line"></hr>
                            </Col>
                        </Row>
                    </div>
                    {/* photo gallery starts */}
                    <div className="mainGallery">
                    <Gallery shareButton={false}>
                        <div className="firstImage">
                        <Item
                                original={image01}
                                thumbnail={image01}
                                width="1920"
                                height="1279"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image01} alt="ripan01"/>
                                )}
                            </Item>
                        </div>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image02}
                                thumbnail={image02}
                                width="1920"
                                height="1281"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image02} alt="ripan02"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image03}
                                thumbnail={image03}
                                width="1920"
                                height="1281"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image03} alt="ripan03"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image04}
                                thumbnail={image04}
                                width="1920"
                                height="1280"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image04} alt="ripan04"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image05}
                                thumbnail={image05}
                                width="1920"
                                height="1280"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image05} alt="ripan05"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        </Gallery>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default RipanBiswas