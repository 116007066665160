import React from 'react';
import './Home.css';
import { Container, Button, Modal } from "react-bootstrap";
import bannerLogo from '../Assets/home/bannerLogo.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Mousewheel, Autoplay } from 'swiper';
import dharmendra from '../Assets/home/dharmendra.png';
import dhiritiman from '../Assets/home/dhritiman.png';
import dinesh from '../Assets/home/dinesh.png';
import ganesh from '../Assets/home/ganesh.png';
import ganeshs from '../Assets/home/ganeshs.png';
import gautam from '../Assets/home/gautam.png';
import giri from '../Assets/home/giri.png';
import jainy from '../Assets/home/jainy.png';
import rajesh from '../Assets/home/rajesh.png';
import ripan from '../Assets/home/ripan.png';
import sachin from '../Assets/home/sachin.png';
import shantanu from '../Assets/home/shantanu.png';
import shivashankar from '../Assets/home/shivashankar.png';
import sudhir from '../Assets/home/sudhir.png';
import suhas from '../Assets/home/suhas.png';
import kalyan from '../Assets/home/kalyan.png';
import Sangram from '../Assets/home/sangram.png';
import VideoIntro from '../Assets/home/intro.mp4';
import ReactPlayer from 'react-player';
import { propTypes } from 'react-bootstrap/esm/Image';

SwiperCore.use([Pagination,Mousewheel, Autoplay]);

const Home = React.forwardRef(function home({allowedToVisit, handleClose, setVideoPlaying}, ref) {
   
    return (
        <div>
            <section className="firstFold">
                <Container>
                    <div className="txtDivFirst">
                        <img data-aos="fade-in" data-aos-duration="2000" src={bannerLogo} className="bannerLogoStyle" alt="mainBannerLogo"></img>
                        <h1 data-aos="fade-in" data-aos-duration="2000" className="firstHead">Welcome to our Photography Exhibition : <span className="colorWhite">
                        Enchanting India</span></h1>
                        <div data-aos="fade-in" data-aos-duration="2000">
                            <a href="/Exhibition" className="btnOutline">VISIT EXHIBITION</a>
                        </div>
                    </div>
                </Container>
            </section>

            <section className="secondFold">
                <Container>
                    <div className="videoDiv">
                    <h1 data-aos="slide-up" data-aos-duration="1000" className="MainHead centerAlign">Photography Exhibition Video Intro</h1>
                        <ReactPlayer ref={ref} onPlay={()=>{setVideoPlaying(true)}} onPause={() => {setVideoPlaying(false)}} className="styleVideo" controls playsinline loop url={VideoIntro} />
                    </div>
                    <div className="secondTxt">
                        <h1 data-aos="slide-up" data-aos-duration="1000" className="MainHead">Our Photographer's</h1>
                        <p data-aos="slide-up" data-aos-duration="1000" className="subHeadSecond">Renowned photographers from across the globe share their inspiration and stunning captures
                                of nature and everything it has to offer.</p>
                    </div>
                    <Swiper data-aos="fade-up" data-aos-duration="2000" className="mainContainerStyle" mousewheel={true} direction={'horizontal'}
                        pagination={{
                            "dynamicBullets": true
                          }}
                        
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        
                        breakpoints={{
                            "640": {
                              "slidesPerView": 2,
                              "spaceBetween": 20
                            },
                            "768": {
                              "slidesPerView": 2,
                              "spaceBetween": 40
                            },
                            "1024": {
                              "slidesPerView": 2,
                              "spaceBetween": 50
                            }
                        }}
                    >
                        
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Dharmendra Khandal</p>
                            <div className="borderStyle">
                                <div className="photographerCardSlide">
                                    <a href="/DharmendraKhandal">
                                        <div className="imagePhotographerSlide">
                                            <img src={dharmendra} className="photographerCardImageSlide" alt="kviff - Dharmendra Khandal"></img>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Dhiritiman Mukharjee</p>
                            <div className="borderStyle">
                                <div className="photographerCardSlide">
                                    <a href="/DhritimanMukharjee">
                                        <div className="imagePhotographerSlide">
                                            <img src={dhiritiman} className="photographerCardImageSlide" alt="kviff - Dhiritiman Mukharjee"></img>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Dinesh Kumble</p>
                            <div className="borderStyle">
                                <div className="photographerCard">
                                    <a href="/DineshKumble">
                                        <div className="imagePhotographerSlide">
                                            <img src={dinesh} className="photographerCardImageSlide" alt="kviff - Dinesh Kumble"></img>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Ganesh Bagal</p>
                                <div className="borderStyle">
                                    <div className="photographerCard">
                                        <a href="/GaneshBagal">
                                            <div className="imagePhotographerSlide">
                                                <img src={ganesh} className="photographerCardImageSlide" alt="kviff - Ganesh Bagal"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Ganesh Shankar</p>
                                <div className="borderStyle">
                                    <div className="photographerCard">
                                        <a href="/GaneshShankar">
                                            <div className="imagePhotographerSlide">
                                                <img src={ganeshs} className="photographerCardImageSlide" alt="kviff - Ganesh Shankar"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Gautam Pandey</p>
                                <div className="borderStyle">
                                    <div className="photographerCard">
                                        <a href="/GautamPandey">
                                            <div className="imagePhotographerSlide">
                                                <img src={gautam} className="photographerCardImageSlide" alt="kviff - Gautam Pandey"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Giri Cavale</p>
                                <div className="borderStyle">
                                    <div className="photographerCard">
                                        <a href="/GiriCavale">
                                            <div className="imagePhotographerSlide">
                                                <img src={giri} className="photographerCardImageSlide" alt="kviff - Giri Cavale"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Jainy Maria</p>
                                <div className="borderStyle">
                                    <div className="photographerCard">
                                        <a href="/JainyMaria">
                                            <div className="imagePhotographerSlide">
                                                <img src={jainy} className="photographerCardImageSlide" alt="kviff - Jainy Maria"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Kalyan Varma</p>
                                <div className="borderStyle">
                                    <div className="photographerCard">
                                        <a href="/KalyanVarma">
                                            <div className="imagePhotographerSlide">
                                                <img src={kalyan} className="photographerCardImageSlide" alt="kviff - Kalyan Varma"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Rajesh Bedi</p>
                                <div className="borderStyle">
                                    <div className="photographerCard">
                                        <a href="/RajeshBedi">
                                            <div className="imagePhotographerSlide">
                                                <img src={rajesh} className="photographerCardImageSlide" alt="kviff - Rajesh Bedi"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Ripan Biswas</p>
                                <div className="borderStyle">
                                    <div className="photographerCard">
                                        <a href="/RipanBiswas">
                                            <div className="imagePhotographerSlide">
                                                <img src={ripan} className="photographerCardImageSlide" alt="kviff - Ripan Biswas"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Sachin Rai</p>
                                <div className="borderStyle">
                                    <div className="photographerCard">
                                        <a href="/SachinRai">
                                            <div className="imagePhotographerSlide">
                                                <img src={sachin} className="photographerCardImageSlide" alt="kviff - Sachin Rai"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Sangram Govardhane</p>
                                <div className="borderStyle">
                                    <div className="photographerCard">
                                        <a href="/SangramGovardhane">
                                            <div className="imagePhotographerSlide">
                                                <img src={Sangram} className="photographerCardImageSlide" alt="kviff - Sangram Govardhane"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Shantanu Prasad</p>
                                <div className="borderStyle">
                                    <div className="photographerCard">
                                        <a href="/ShantanuPrasad">
                                            <div className="imagePhotographerSlide">
                                                <img src={shantanu} className="photographerCardImageSlide" alt="kviff - Shantanu Prasad"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Shivashankar Banagar</p>
                                <div className="borderStyle">
                                    <div className="photographerCard">
                                        <a href="/ShivashankarBanagar">
                                            <div className="imagePhotographerSlide">
                                                <img src={shivashankar} className="photographerCardImageSlide" alt="kviff - Shivashankar Banagar"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Sudhir Shivaram</p>
                                <div className="borderStyle">
                                    <div className="photographerCard">
                                        <a href="/SudhirShivaram">
                                            <div className="imagePhotographerSlide">
                                                <img src={sudhir} className="photographerCardImageSlide" alt="kviff - Sudhir Shivaram"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSlider">
                            <p className="titleV">Suhas Nalawade</p>
                                <div className="borderStyle">
                                    <div className="photographerCard">
                                        <a href="/SuhasNalawade">
                                            <div className="imagePhotographerSlide">
                                                <img src={suhas} className="photographerCardImageSlide" alt="kviff - Suhas Nalawade"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide className="styleSliderRight">
                        
                        </SwiperSlide>
                        <SwiperSlide className="styleSliderRight">
                       
                        </SwiperSlide>
                        <SwiperSlide className="styleSliderRight">
                       
                        </SwiperSlide>
                    </Swiper>
                </Container>
                    <div>

                            <Modal
                                show={!allowedToVisit}
                                onHide={handleClose}
                                backdrop="static"
                                keyboard={false}
                            >
                        <Modal.Header closeButton>
                            <Modal.Title className="disclaimerTitle">Disclaimer</Modal.Title>
                        </Modal.Header>
                            <Modal.Body className="paraModal">
                            The photograph(s) is / are copyright protected. ©  
                            Copying, reproducing, distributing, transmitting, modifying, creating derivative works, or in any other way exploiting any part of the copyrighted material without  the prior written permission from the owner is punishable under Copyright Act.
                            </Modal.Body>
                        <Modal.Footer>
                            <Button className="modalAgreeBtn" onClick={handleClose}>
                                I Agree 
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </section>
        </div>
    )
})

export default Home