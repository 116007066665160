import React from 'react';
import './StyleCommon.css';
import { Container, Row, Col } from "react-bootstrap";
import sudhir from '../Assets/photographer/sudhir/sudhir.png';
import image01 from '../Assets/photographer/sudhir/01.png';
import image02 from '../Assets/photographer/sudhir/02.png';
import image03 from '../Assets/photographer/sudhir/03.png';
import image04 from '../Assets/photographer/sudhir/04.png';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';


function SudhirShivaram() {
  
    return (
        <div>
            <section className="photoExhibitionDiv">
                <Container>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={3} xl={3}>
                            <div className="alignLeft">
                                <img data-aos="fade-down" data-aos-duration="3000" src={sudhir} className="profileImage" alt="Sudhir Shivaram"></img>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={9} xl={9}>
                            <div data-aos="fade-up" data-aos-duration="3000">
                                <h4 className="namePhotographer">Sudhir Shivaram</h4>
                                <p className="aboutPhotographer">Sudhir Shivaram is much loved, recognized, and respected in wildlife photography circles not only in India but the world over. Engineer by education, photographer by passion, teacher by intuition, Sudhir is an inspiration to scores of people that aspire to break away from the mundane and chase their dreams.  
                                <br></br> <br></br>
                                He is one of India’s most respected and renowned wildlife photographers. A long and lucrative career in the IT industry did not diminish Sudhir’s passion for photography but on the contrary, ensured that he never lost touch with his wild side. Sudhir was one of the brand ambassadors for Canon India and a Canon contract photographer.
                                <br></br> <br></br>
                                He received the Sanctuary Asia Wildlife Photographer of the Year 2012 award. He has figured in Asian Photography Magazine’s Top Ten Most Influential People in photography industry in India for five consecutive years since 2015 and awarded the top honour as India’s ‘First’ Most Influential from among a chosen group that comprised top officials of global multinationals. All credentials aside, the thing Sudhir loves the most is to shoot in the wild and teach others how to shoot. 
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div className="divider">
                        <Row>
                            <Col className="p-0" sm={1} xs={1} md={5} lg={5} xl={5}>
                                <hr className="line"></hr>
                            </Col>
                            <Col className="p-0" sm={10} xs={10} md={5} lg={5} xl={5}>
                                <p className="dividerTxt">Photograph’s By Sudhir Shivaram</p>
                            </Col>
                            <Col className="p-0" sm={1} xs={1} md={2} lg={2} xl={2}>
                                <hr className="line"></hr>
                            </Col>
                        </Row>
                    </div>
                    {/* photo gallery starts */}
                    <div className="mainGallery">
                    <Gallery shareButton={false}>
                        <div className="firstImage">
                        <Item
                                original={image01}
                                thumbnail={image01}
                                width="1920"
                                height="1281"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image01} alt="sudhir01"/>
                                )}
                            </Item>
                        </div>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image02}
                                thumbnail={image02}
                                width="1080"
                                height="1619"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image02} alt="sudhir02"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image03}
                                thumbnail={image03}
                                width="1080"
                                height="1619"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image03} alt="sudhir03"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image04}
                                thumbnail={image04}
                                width="1080"
                                height="1619"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image04} alt="sudhir04"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            </Col>
                        </Row>
                        </Gallery>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default SudhirShivaram