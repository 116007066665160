import React  from 'react'
import './Theme.css';
import { Container } from "react-bootstrap";
import AboutMain from "../Assets/about/aboutMain.png";
import image1 from "../Assets/about/image1.png";
import image2 from "../Assets/about/image2.png";
import image3 from "../Assets/about/image3.png";
import image4 from "../Assets/about/image4.png";
import image5 from "../Assets/about/image5.png";
import image6 from "../Assets/about/image6.png";
import image7 from "../Assets/about/image7.png";
import image8 from "../Assets/about/image8.png";
import image9 from "../Assets/about/image9.png";
import image10 from "../Assets/about/image10.png";
import image11 from "../Assets/about/image11.png";
import image12 from "../Assets/about/image12.png";
import image13 from "../Assets/about/image13.png";
import image14 from "../Assets/about/image14.png";
import image15 from "../Assets/about/image15.png";
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Pagination]);

function Theme() {
  
    return (
        <div>
            <section className="aboutDivFirst">
                <div id="opening" className="zoomImageAbout">
                    <img class="opening-image zooming" src={AboutMain} alt="kVIFF About Banner"></img>
                </div>
            </section>
            <section className="aboutContentDiv">
                <Container>
                    <h1 data-aos="fade-up" data-aos-duration="2000" className="aboutMainHead">Welcome to our Photography Exhibition : <span className="colorWhite">
                        Enchanting India</span></h1>
                    <p data-aos="fade-up" data-aos-duration="2000" className="bodyPara">From coral reefs to evergreen rain forests, swamps to cold deserts and mangroves to Himalaya - India has it all. It is home to nearly 8 percent of the species found on our planet. The biodiversity provides ecological goods and services, necessary for human survival and provides livelihoods to millions of local people. Coinciding with the natural diversity is the vast diversity in cultures, food habits and relationship with nature.
                        <br></br><br></br>
                            Perhaps in no other place in the world will you find communities living at 4400 meters above sea level, as in Hikkim; or in 10,000 mm of rainfall, as in Mawsynram and where temperatures soar above 50 degrees Celsius, like Phalodi.</p>
                    <p data-aos="fade" data-aos-duration="3000" className="txtRed">Welcome to a Megadiverse country.</p>
                    <p data-aos="fade" data-aos-duration="3000" className="txtGreen">Welcome to Enchanting India.</p>
                </Container>
            </section>
            <section className="aboutDivSecond">
            <Swiper slidesPerView={3} spaceBetween={0} pagination={{ "dynamicBullets": true }} autoplay={{ "delay": 3000, "disableOnInteraction": false }} className="mySwiper">
                <SwiperSlide>
                    <img data-aos="fade-up" data-aos-duration="3000" src={image1} className="aboutSecondImage" alt="kirloskars about image01"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img data-aos="fade-up" data-aos-duration="3000" src={image2} className="aboutSecondImage" alt="kirloskars about image02"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img data-aos="fade-up" data-aos-duration="3000" src={image3} className="aboutSecondImage" alt="kirloskars about image03"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img data-aos="fade-up" data-aos-duration="3000" src={image4} className="aboutSecondImage" alt="kirloskars about image04"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img data-aos="fade-up" data-aos-duration="3000" src={image5} className="aboutSecondImage" alt="kirloskars about image05"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img data-aos="fade-up" data-aos-duration="3000" src={image6} className="aboutSecondImage" alt="kirloskars about image06"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img data-aos="fade-up" data-aos-duration="3000" src={image7} className="aboutSecondImage" alt="kirloskars about image07"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img data-aos="fade-up" data-aos-duration="3000" src={image8} className="aboutSecondImage" alt="kirloskars about image08"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img data-aos="fade-up" data-aos-duration="3000" src={image9} className="aboutSecondImage" alt="kirloskars about image09"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img data-aos="fade-up" data-aos-duration="3000" src={image10} className="aboutSecondImage" alt="kirloskars about image10"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img data-aos="fade-up" data-aos-duration="3000" src={image11} className="aboutSecondImage" alt="kirloskars about image11"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img data-aos="fade-up" data-aos-duration="3000" src={image12} className="aboutSecondImage" alt="kirloskars about image12"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img data-aos="fade-up" data-aos-duration="3000" src={image13} className="aboutSecondImage" alt="kirloskars about image13"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img data-aos="fade-up" data-aos-duration="3000" src={image14} className="aboutSecondImage" alt="kirloskars about image14"></img>
                </SwiperSlide>
                <SwiperSlide>
                    <img data-aos="fade-up" data-aos-duration="3000" src={image15} className="aboutSecondImage" alt="kirloskars about image15"></img>
                </SwiperSlide>
            </Swiper>
            </section>

        </div>
    )
}

export default Theme