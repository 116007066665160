import React from 'react';
import './StyleCommon.css';
import { Container, Row, Col } from "react-bootstrap";
import shantanu from '../Assets/photographer/shantanu/shantanu.png';
import image01 from '../Assets/photographer/shantanu/01.png';
import image02 from '../Assets/photographer/shantanu/02.png';
import image03 from '../Assets/photographer/shantanu/03.png';
import image04 from '../Assets/photographer/shantanu/04.png';
import image05 from '../Assets/photographer/shantanu/05.png';
import image06 from '../Assets/photographer/shantanu/06.png';
import image07 from '../Assets/photographer/shantanu/07.png';
import image08 from '../Assets/photographer/shantanu/08.png';
import image09 from '../Assets/photographer/shantanu/09.png';
import image10 from '../Assets/photographer/shantanu/10.png';
import image11 from '../Assets/photographer/shantanu/11.png';
import image12 from '../Assets/photographer/shantanu/12.png';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';


function ShantanuPrasad() {
  
    return (
        <div>
            <section className="photoExhibitionDiv">
                <Container>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={3} xl={3}>
                            <div className="alignLeft">
                                <img data-aos="fade-down" data-aos-duration="3000" src={shantanu} className="profileImage" alt="Shantanu Prasad"></img>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={9} xl={9}>
                            <div data-aos="fade-up" data-aos-duration="3000">
                                <h4 className="namePhotographer">Shantanu Prasad</h4>
                                <p className="aboutPhotographer">Shantanu Prasad is a Wildlife Photographer, Naturalist, Researcher, Conservationist, Author & an entrepreneur. For the past 11 years he spent around 60,000 hrs into the wild habitats of India, Nepal & Africa exploring, documenting & conserving different endangered wildlife. A Biotech engineer by profession, it helped him to pursue an all rounder role in the Wildlife field with a clear scientific understanding.
                                <br></br><br></br>
                                He specializes in setting up Research & Conservation projects on endangered species in different unprotected habitats. He started a sustainable tourism project focusing on Red Panda conservation involving locals and the community called 'Habre's Nest' in Singalila Himalayas, they do research & conservation work on Red Panda from the profit of Wildlife Tourism run by Habre's Nest. He also had a similar project on Fishing Cat at Howrah called 'Baghrol Basa'.
                                <br></br><br></br>
                                His Images published in many prestigious Magazines like WWF, Saevus, Better Photography, Sanctuary Asia etc. As a naturalist he assisted  wildlife filming teams published on Discovery, Animal Planet etc. His research work was published in IUCN cat news. He is also co-author of the Bird field guide book of West Bengal called 'Banglar PakhPakhali'. "Ultimately it's all about the love for Wildlife and passion for Photography where all my energies are spent to save them"- according to Shantanu.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="divider">
                        <Row>
                            <Col className="p-0" sm={1} xs={1} md={5} lg={5} xl={5}>
                                <hr className="line"></hr>
                            </Col>
                            <Col className="p-0" sm={10} xs={10} md={5} lg={5} xl={5}>
                                <p className="dividerTxt">Photograph’s By Shantanu Prasad</p>
                            </Col>
                            <Col className="p-0" sm={1} xs={1} md={2} lg={2} xl={2}>
                                <hr className="line"></hr>
                            </Col>
                        </Row>
                    </div>
                    {/* photo gallery starts */}
                    <div className="mainGallery">
                    <Gallery shareButton={false}>
                        <div className="firstImage">
                        <Item
                                original={image01}
                                thumbnail={image01}
                                width="1920"
                                height="1280"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image01} alt="shantanu01"/>
                                )}
                            </Item>
                        </div>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image02}
                                thumbnail={image02}
                                width="1920"
                                height="1280"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image02} alt="shantanu02"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image03}
                                thumbnail={image03}
                                width="1920"
                                height="1280"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image03} alt="shantanu03"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image04}
                                thumbnail={image04}
                                width="1920"
                                height="1280"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image04} alt="shantanu04"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image05}
                                thumbnail={image05}
                                width="1920"
                                height="1280"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image05} alt="shantanu05"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image06}
                                thumbnail={image06}
                                width="1920"
                                height="1280"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image06} alt="shantanu06"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image07}
                                thumbnail={image07}
                                width="1920"
                                height="1200"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image07} alt="shantanu07"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image08}
                                thumbnail={image08}
                                width="1920"
                                height="1200"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image08} alt="shantanu08"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image09}
                                thumbnail={image09}
                                width="1920"
                                height="1200"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image09} alt="shantanu09"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image10}
                                thumbnail={image10}
                                width="1920"
                                height="1920"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image10} alt="shantanu10"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image11}
                                thumbnail={image11}
                                width="1920"
                                height="1335"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust" src={image11} alt="shantanu11"/>
                                )}
                            </Item>
                            </Col>
                        </Row>
                        <Row className="firstImage">
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Item
                                original={image12}
                                thumbnail={image12}
                                width="1280"
                                height="1920"
                            >
                                {({ ref, open }) => (
                                <img data-aos="fade" data-aos-duration="3000" ref={ref} onClick={open} className="styleAdjust styleBottomImg" src={image12} alt="shantanu12"/>
                                )}
                            </Item>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            </Col>
                        </Row>
                        </Gallery>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default ShantanuPrasad